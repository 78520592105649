.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.input-group {
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.pricing-box {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.pricing-box,
.personal-info-box,
.credit-card-info-box,
.price{
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 20px;
}

.boxing-container{
    width: 60%;
}

.pricing-item {
    display: flex;
    flex-basis: 50%;
}

.pricing-item > div {
    flex: 1;
}

.pricing-item h4 {
    margin-bottom: 5px;
}

.input{
    width: 100%;
    padding: 10px;
    border: #222222;
    border-radius: 5px;
    margin-right: 15px;
    font-size: 18px;
    color: gray;
    justify-content: center;
}

.input:focus{
    background-color: #fff;
    border-radius: 4px;
    transition: 0.2s ease-in-out;
    outline: white;
}

.input-group label {
    width: 150px;
    text-align: right;
    margin-right: 20px;
    flex-shrink: 0;
}

.element-pricing{
    flex: 1;
}

.price {
    display: flex;
    justify-content: space-between;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 5px;
}

.book-button[disabled]{
    opacity: 0.5;
    cursor: not-allowed;

}
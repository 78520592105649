.trip {
  margin: 4rem 6rem;
  color: #2a2a2a;
}
.trip h1 {
  font-size: 3rem;
}
.trip-card {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
}
.t-card {
  width: 32%;
  text-align: start;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  cursor: pointer;
  padding: 1rem 0.8rem;
}
.t-img {
  height: 200px;
  overflow: hidden;
  border-radius: 7px;
}
.t-img img {
  width: 100%;
  height: 100%;
  transition: 0.3s ease-in-out;
  border-radius: 7px;
}
.t-img:hover img {
  transform: scale(1.3);
}
.t-card h4 {
  font-size: 1.2rem;
  padding: 0.9rem 0 0.2rem 0;
}

@media screen and (max-width: 850px) {
  .trip {
    margin: 4rem 2rem;
  }
  .trip-card {
    flex-direction: column;
  }
  .t-card {
    width: 100%;
    margin-bottom: 1.5rem;
  }
}

.book-button {
  background-color: #01959a;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  margin: 15px;
}

.book-button:hover {
  background-color: #01524f;
}

h2{
  margin: 15px;

}

h3{
  margin: 15px;
}
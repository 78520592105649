.hero {
  width: 100%;
  height: 100vh;
  position: relative;
}
.hero-mid {
  width: 100%;
  height: 70vh;
  position: relative;
}
.hero-mid h1 {
  padding-top: 4rem !important;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hero-text {
  position: absolute;
  top: 28%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.hero-text h1 {
  font-size: 3rem;
  font-weight: 100000;
  background: #181818;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.hero-text p {
  font-size: 1.3rem;
  color: #111111;
  padding: 0.5rem 0.2rem 0;
}

@media screen and (max-width: 555px) {
  .hero-text h1 {
    padding: 10px 20px;
  }
  .hero-text p {
    font-size: 1.1rem;
    padding: 0 0 2rem 0;
  }
  .hero-text .show {
    padding: 0.6rem 1.1rem;
    border-radius: 6px;
    font-size: 1rem;
  }
}

.search-form {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.search-input,
.search-select {
  width: 250px;
  padding: 10px;
  border: #222222;
  border-radius: 5px;
  margin-right: 15px;
  font-size: 18px;
  color: gray;
}

.search-input:focus{
  background-color: #fff;
  border-radius: 4px;
  transition: 0.2s ease-in-out;
  outline: white;
}

.hero-text .show {
  text-decoration: none;
  background: #ffffff;
  padding: 0.50rem 2rem;
  border-radius: 6px;
  font-size: 1.1rem;
  font-weight: bold;
  letter-spacing: 2px;
  color: black;
}
.hero-text .show:hover {
  background-color: #151516;
  color: #fff;
  border-radius: 4px;
  transition: 0.2s ease-in-out;
}

.thanks-page .isEnabled{
  display: none;
}

.aboutPage .isEnabled{
  display: none;
}

.contactPage .isEnabled{
  display: none;
}

.singUpPage .isEnabled{
  display: none;
}

.bookFligthPage .isEnabled{
  display: none;
}

.search-select {
  padding: 10px;
  border: #222222;
  border-radius: 5px;
  margin-right: 15px;
  font-size: 18px;
}

.link-unstyled {
  color: inherit;
  text-decoration: none;
}


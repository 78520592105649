.footer {
  padding: 1rem 7rem;
  background-color: black;
  color: white;
}
.top {
  display: flex;
  align-items: center;
  text-align: start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.top p {
  color: #fafafa88;
}
.top i {
  color: white;
  font-size: 1.5rem;
  margin-left: 1rem;
}
.top i:hover {
  color: #97dfe2;
}
.bottom {
  padding-top: 2rem;
  display: flex;
  text-align: start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.bottom div {
  display: flex;
  flex-direction: column;
}
.bottom h4 {
  font-size: 1.2rem;
  padding: 1rem 0 0.8rem 0;
}
.bottom a {
  text-decoration: none;
  color: #fafafa88;
  padding-bottom: 0.4rem;
  font-size: 1rem;
}

@media screen and (max-width: 850px) {
  .footer {
    padding: 4rem 2rem;
  }
  .footer i {
    margin: 1rem 1rem 0 0;
  }
  .bottom div {
    width: 50%;
  }
}

.copyright{
  margin-top: 20px;
}
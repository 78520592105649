@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;1,600&family=Source+Sans+Pro&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.App {
  text-align: center;
  font-family: 'Poppins', sans-serif;
}